import {Controller} from 'stimulus'
import throttle from 'lodash/throttle'
import Swiper, {Pagination, EffectFade} from 'swiper'

Swiper.use([Pagination, EffectFade])

export default class extends Controller {
  static targets = ['slider']

  connect() {
    const eventCards = this.element.querySelectorAll('.event_card');
    const lastEventCard = eventCards[eventCards.length - 1];
    if (lastEventCard) {
      lastEventCard.addEventListener('eventCardProcessed', this.handleLastEventCardProcessed);
    }
  }

  disconnect() {
    const eventCards = this.element.querySelectorAll('.event_card');
    const lastEventCard = eventCards[eventCards.length - 1];
    if (lastEventCard) {
      lastEventCard.removeEventListener('eventCardProcessed', this.handleLastEventCardProcessed);
    }
    if (this.swiper) {
      this.swiper.destroy(true, true)
    }
  }

  handleLastEventCardProcessed = () => {
    // limit the slider to 3 slides
    document.querySelectorAll('.event_card').forEach((el, index) => {
      if (index >= 3) el.remove();
    });
    this.initSlider();
  };

  initSlider() {
    this.swiper = new Swiper(this.sliderTarget, {
      slidesPerView: 1,
      centeredSlides: true,
      speed: 800,
      spaceBetween: 30,
      autoHeight: false,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      breakpoints: {
        700: {
          centeredSlides: false,
          slidesPerView: 2,
        },
        1024: {
          // We'll need to set centeredSlides to true again and use centeredSlidesBounds to remove any empty space
          // Disabling the Swiper instance doesn't behave as expected consistently on resize
          slidesPerView: 3,
          centeredSlides: true,
          centeredSlidesBounds: true,
        },
      },
    })
  }
}
